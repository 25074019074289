.location {
  margin-bottom: 83px !important;
  .slider {
    position: relative;
    left: 160px;
    opacity: 0;
    transition: 1s;
  }
  .map {
    position: absolute;
    top: 0px;
    right: 200px;
    transition: 1s;
    opacity: 0;
    max-width: 80%;
    img {
      display: block;
      width: 100%;
    }

    &:after {
      content: '';
      width: 100px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
      border-radius: 100px;
    }
  }
  .text {
    position: relative;
    top: 80px;
    max-width: 538px;
    opacity: 0;
    transition: 1s;
    p {
      margin-bottom: 15px;
    }
  }
  .animation {
    &.slider {
      left: 0;
      opacity: 1;

    }
    &.map {
      opacity: 1;
      top: 0;
      right: -80px;

    }
    &.text {
      top: 0;
      opacity: 1;
    }
  }
  .title {
    text-transform: uppercase;
    font-weight: 600;
  }
  .list {
    list-style-type: none;
    padding-left: 0;
    span {
      font-size: 48px;
      line-height: 48px;
      margin-right: 12px;
    }
  }
}

@media all and (max-width: 1439px) {
  .location {
    .text {
      max-width: 450px;
    }
    .animation {
      &.map {
        top: 118px;
      }
    }
  }
}

@media all and (max-width: 700px) {
  .location {
    padding: 0px 20px;
    .text {
      max-width: 100%;
    }
    .map {
      display: none;
    }
    .button {
      font-size: 14px;
      padding: 16px;
    }
  }
}

@media all and (max-width: 420px) {
  .location .button {
    width: 100%;
    text-align: center;
  }
}